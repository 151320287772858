import React, { useState,useEffect,useCallback} from 'react';
import { AppProvider,FormLayout,TextField,PageActions,Layout,Form } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import Api from './Services/CommonServices';
// import { environment } from './environments/environment.prod';
const api = new Api();
// let storeUrl = "";

export function VerifyShop() {
const [shopUrl, setShopUrl] = useState('');
var queryParams = {};
const handleChange = useCallback((newValue) => setShopUrl(newValue), []);

useEffect(() => {
  // localStorage.removeItem("shopUrl");
  getStoreUrl();
}, []);

async function generateAuthUrl() {
    api.generateAuthUrl(shopUrl)
    .then(res => {
      console.log(`res = ${res}`);
      window.open(res, '_top');
    }, err => {
      console.log(`err = ${JSON.stringify(err)}`);
    });
  }

  // async function checkIsShopExists(){
  //   return new Promise((resolve, reject) => {
  //       api.checkIsShopExits(shopUrl)
  //     .then(res => {
  //       console.log(`res = ${res}`);
  //       resolve(res);
  //     }, err => {
  //       console.log(`err = ${JSON.stringify(err)}`);
  //       reject();
  //     });
  //   });
  // }

  const handleFormSubmit =(evt) =>{
    evt.preventDefault();
    getStarted()
    console.log(shopUrl);
  }

async function getStoreUrl()
{
 console.log(`queryparams = ${JSON.stringify(api.QueryStringToJSON())}`);
 queryParams = api.QueryStringToJSON();

 console.log("currentUrl",queryParams)
//  if(queryParams == null)
//  {
//    return <Redirect to = "/settings"/>
//  }
 if (queryParams && queryParams.shop) {
  setShopUrl(queryParams.shop);
   console.log(`shopUrl = ${shopUrl}`);
 }
}
async function getStarted() {
    shopUrl.trim();
    generateAuthUrl();
    // if (shopUrl) {
    //   checkIsShopExists()
    //   .then(res => {
    //     console.log("WindowOpen",res);
    //     if (res) {

    //       window.open(`https://` + shopUrl + `/admin/apps/${environment.appName}`, '_top');
    //     } else {
    //       generateAuthUrl();
    //     }
    //   })
    //   .catch(err => {
    //   });
    //     generateAuthUrl();
    // // } else {
    // }
  }
  // const config = {apiKey: environment.apiKey, shopOrigin: queryParams.shop};
  // render() {
  //   if(this.state.toDashboard) {
      // return <Redirect to="settings"/>
    // }
    // if(localStorage.getItem('shopUrl')!=null ){
    //   return <Layout1><Route path='/settings' component={SettingsForm} />
    //     <Route path='/logs' component={Logs} />
    //     <Route path='/privacy' component={PrivacyPolicy} /></Layout1>
    // }
    // else {
    //   return <Redirect to="settings"/>
    // }
    return (

      <AppProvider i18n={enTranslations}>
        {/* <Provider config={config}>
        <Loading />
        <Card /> */}
      <Form onSubmit={handleFormSubmit}>
      <Layout>
      <Layout.AnnotatedSection>
      <FormLayout>
      <TextField label="Enter your store url" helpText="Shop Domain to get started" id="storeName" value={shopUrl} onChange={handleChange}/>
      <Layout.Section>
      <PageActions
        primaryAction={{
        content: 'Get Started',
        submit: true,
        }}
      />
      </Layout.Section>
      </FormLayout>
      </Layout.AnnotatedSection>
      </Layout>
      </Form>
      {/* </Provider> */}
      </AppProvider>


    );
  }

  export default VerifyShop
