
import Api from '../Services/CommonServices';
const api = new Api();
var shopOrigin ={};
export class requireAuth {

  canActivate() {
    localStorage.removeItem('shopExist');
    shopOrigin = api.QueryStringToJSON();
    if(shopOrigin.shop !== undefined){
    localStorage.setItem('shopUrl', shopOrigin.shop);
    }
    return new Promise((resolve, reject) => {
      if (api.isHmacVerified) {
        if (!(api.recurringChargeStatus === 'Active')) {
          api.createRecurringCharge(api.QueryStringToJSON().shop)
          .then(recurringCharge => {
            window.open(recurringCharge, '_top');
          }, err => {
            console.log(`error = > ${JSON.stringify((err))}`);
          });
        } else {
          resolve(true);
        }
      }
    });
  }


  verifyHmac(queryString) {
    return new Promise((resolve, reject) => {
      api.verifyShop(queryString)
      .then((res) => {
        api.isHmacVerified = res.isVerifiedRequest;
        api.recurringChargeStatus = res.recurringChargeStatus;
        resolve(res);
      }, err => {
        reject(err.error);
      });
    });
  }


}


