import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout1 } from './components/Layout1';
import { VerifyShop } from './components/VerifyShop';
import { requireAuth } from './components/Auth/auth';
import { AppProvider } from '@shopify/polaris';
// import './custom.css'
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { QboSetting } from './components/QuickbooksSetup/QboSetting';
// import { TallySetup } from './components/TallySetup/TallySetup';
import { Setting } from './components/Setting';
import { TallyConfig } from './components/TallySetup/TallyConfig';
import enTranslations from '@shopify/polaris/locales/en.json';
const auth = new requireAuth();
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <AppProvider i18n={enTranslations} >
      <Layout1>
        <Route exact path='/' component={Setting} appProps={  auth.canActivate() }/>
        <Route path='/tally-setup' component={TallyConfig}  />
        <Route path='/VerifyShop' component={VerifyShop} />
        <Route path='/qbo-setup' component={QboSetting}  />
        <Route path='/privacy' component={PrivacyPolicy} />
      </Layout1>
      </AppProvider>
    );
  }
}
