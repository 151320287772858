import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Card, FormLayout, TextField, PageActions, Form, Select, Button, AppProvider, TextStyle, ChoiceList, Subheading, Layout, Page, SettingToggle, RadioButton, Stack, Icon, Banner, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
import enTranslations from '@shopify/polaris/locales/en.json';
import UseLoadingText from '../Toast/UseLoadingText';
import { TallyEmptyState } from './TallyEmptyState';
import { ArrowDownMinor } from '@shopify/polaris-icons';
import { Setting } from '../Setting';
const api = new Api();
const endpoint = environment.serverUrl;
export function TallyConfig() {
  const [loader, showLoader, hideLoader] = useLoader();
  const [loading, showLoadingText, hideLoadingText] = UseLoadingText();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const handleSelectSalesLedgerChange = useCallback((value) => setSelectedSalesLedger(value), []);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [tallyIp, setTallyIp] = useState('');
  const [companyName, setCompanyName] = useState([]);
  const [tallyPort, setTallyPort] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [tallyStatus, setTallyStatus] = useState(true);
  const [frequency, setFrequency] = useState('5');
  const handleTallyIpChange = useCallback(newValue => setTallyIp(newValue), []);
  const handleTallyPortChange = useCallback(newValue => setTallyPort(newValue), []);
  const handleFrequencyChange = useCallback(newValue => setFrequency(newValue), []);
  const [salesLedger, setSalesLedger] = useState([]);
  const [partyLedger, setPartyLedger] = useState([]);
  const [interval, setInterval] = useState(150000);
  const [verifyPortInterval, setVerifyPortInterval] = useState(150000);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedSalesLedger, setSelectedSalesLedger] = useState('');
  const [selectedPartyLedger, setSelectedPartyLedger] = useState('');
  const [existCompanyName, setExistCompanyName] = useState('');
  const [selectedGodowns, setSelectedGodowns] = useState([]);
  const [godowns, setGodowns] = useState([]);
  const handleCompanyNameChange = useCallback((newValue) => setSelectedCompanyName(newValue), [])
  const handleGodownCheckChange = useCallback((newChecked) => setSelectedGodowns(newChecked), []);
  const handlePartLedgerChange = useCallback((value) => setSelectedPartyLedger(value), []);
  const [skuField, setSkuField] = useState('AliasOnly');
  const handleSkuFieldChange = useCallback((value) => setSkuField(value), []);
  const [value, setValue] = useState('fixed');
  const [goBackActive, setGoBackActive] = useState(false);
  const handleChange = useCallback(
    (_checked, newValue) => setValue(newValue),
    [],
  );
  const skuFieldOptions = [
    { label: 'Alias Only', value: 'AliasOnly' },
    { label: 'Name Only', value: 'NameOnly' },
    { label: 'PartNo Only', value: 'PartNoOnly' },
  ];
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const frequencyOptions = [
    { label: '5', value: '5' },
    { label: '10', value: '10' },
    { label: '15', value: '15' },
    { label: '45', value: '45' },
    { label: '60', value: '60' },
  ];
  const timer = useRef(null);
  const verifyPortTimer = useRef(null);
  const shopOrigin = useRef('');
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    console.log("shopOrigin=", shopOrigin);
    getTallyConfig();
    getTallyCompanies();
    getTallyGodowns();
    getStore();
    getTallyPartyLedger();
    CheckConnectionStatus();
  }, []);

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    if (existCompanyName !== selectedCompanyName) { deleteTallyResponse(); }
    addTallyConfigs();
    getTallyPartyLedger();
    getTallyConfig();
  }


  const handleVerifyPort = (evt) => {

    evt.preventDefault(tallyIp, tallyPort);
    api.deleteConnectionStatus(shopOrigin.current.shop);
    addTallyConfigs();
    CallVerifyTimer();
  }

  const handleFormToggle = (evt) => {
    evt.preventDefault();
    addTallyConfigs();
  }

  const CallVerifyTimer = async () => {
    showLoader();
    await delay(5000);
    TimerPortVerify();
  };

  async function getTallyConfig() {
    setIsLoading(true);
    showLoader();
    api.getTallyConfig(shopOrigin.current.shop)
      .then(data => {
        setActive(data.tallify_status)
        setSelectedCompanyName('' + data.company_name);
        setExistCompanyName('' + data.company_name);
        setTallyIp('' + data.tally_ip)
        setTallyPort('' + data.tally_port)
        setFrequency('' + data.frequency);
        setSelectedSalesLedger('' + data.sales_ledger)
        setSelectedPartyLedger('' + data.party_ledger)
        data.ledger_status !== "" ? setValue('' + data.ledger_status) : setValue('fixed');
        data.godowns != null ? setSelectedGodowns(data.godowns) : setSelectedGodowns("");
      });
    getTallySalesLedger();
    getTallyGodowns();
  }

  function CheckConnectionStatus() {
    api.CheckConnectionStatus(shopOrigin.current.shop)
      .then(response => {
        if (response !== "error") {
          setVerifyPortInterval(200000)
          hideLoader();
          setIsLoading(false);
          setTallyStatus(response);
        }
        else {
          TimerPortVerify();
        }
      });
  }

  async function getStore() {
    api.getStore(shopOrigin.current.shop)
      .then(data => {
        setApiKey(data.tallyApiKey);
      });
  }

  async function deleteTallyResponse() {
    api.deleteTallyResponse(shopOrigin.current.shop);
  }

  async function getTallyCompanies() {
    api.getTallyCompanies(shopOrigin.current.shop)
      .then(data => {
        if (data[0] !== undefined) {
          if (selectedCompanyName === undefined) { setSelectedCompanyName('' + data[0].companyName); }
        }
        setCompanyName(data);
      });
  }


  function TimerGetLedgers() {
    setIsLoading(true);
    showLoader();
    timer.current = setInterval(() =>

      getTallyPartyLedger(), interval);
    return () => {
      clearInterval(timer.current);
    };
  }

  function TimerPortVerify() {
    setIsLoading(true);
    showLoader();
    verifyPortTimer.current = setVerifyPortInterval(() =>

      CheckConnectionStatus(), verifyPortInterval);
    return () => {
      clearInterval(timer.current);
    };
  }


  async function getTallyPartyLedger() {
    api.getTallyPartyLedger(shopOrigin.current.shop)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          getTallyConfig();
          setInterval(200000);
          hideLoader();
          setIsLoading(false);
          hideLoadingText();
          if (response.data[0] !== undefined) {
            setSelectedPartyLedger('' + response.data[0].partyLedgerName)
            setPartyLedger(response.data);
          }
        }
        else {
          showLoadingText()
          TimerGetLedgers();
        }
      });
  }

  async function getTallySalesLedger() {
    api.getTallySalesLedger(shopOrigin.current.shop)
      .then(data => {
        setSalesLedger(data);
      });
  }

  async function getTallyGodowns() {
    api.getTallyGodowns(shopOrigin.current.shop)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          setGodowns(response.data)
          hideLoader();
          setIsLoading(false);
          hideLoadingText();
        }
        else {
          showLoadingText()
          TimerGetLedgers();
        }
      })
  }

  async function addTallyConfigs() {
    const data = {
      sales_ledger: selectedSalesLedger,
      party_ledger: selectedPartyLedger,
      company_name: selectedCompanyName,
      frequency: frequency,
      godowns: selectedGodowns,
      tally_ip: tallyIp,
      tally_port: tallyPort,
      sku_field: skuField,
      shop_url: shopOrigin.current.shop,
      ledger_status: value,
      tallify_status: active
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(endpoint + '/api/TallyConfig/add-tally-config?shopUrl=' + shopOrigin.current.shop, requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  const companyOptions = []
  companyName.forEach(com => {
    const data = {
      value: com.companyName, label: com.companyName
    };
    companyOptions.push(data);
  })

  const partyLedgerOptions = []
    ;
  partyLedger.forEach(per => {

    const ledger = {
      value: per.partyLedgerName, label: per.partyLedgerName
    };
    partyLedgerOptions.push(ledger)
  })

  const salesLedgerOptions = []
  salesLedger.forEach(per => {
    const salesledger = {
      value: per.salesLedgerName, label: per.salesLedgerName
    };
    salesLedgerOptions.push(salesledger)
  })

  const godownOptions = []
  godowns.forEach(per => {
    const godown = {
      value: per.godownName, label: per.godownName
    };
    godownOptions.push(godown)
  })

  const downloadBanner = (
    <Banner
      title="Console App"
      action={{ content: <Icon source={ArrowDownMinor} />, onAction: () => { window.open('https://drive.google.com/file/d/1H-hwW4dN4KWIjDEj1n8tz2nsc-p7jAT4/view?usp=sharing') } }}
      status="info"
    >
      <p>Please download console app.</p>
    </Banner>
  );



  console.log("value=", isLoading);
  return (
    <>
      {goBackActive == false ?
        <Page breadcrumbs={[{ content: 'Settings', onAction: goBack }]}>
          {loader}
          <Layout>
            <Layout.AnnotatedSection
            >
              {downloadBanner}
              <br />
              <Card>
                <Form onSubmit={handleFormToggle}>
                  <SettingToggle
                    action={{
                      content: contentStatus,
                      onAction: handleToggle,
                      submit: true
                    }}
                    enabled={active}>
                    Tallify is <TextStyle variation="strong">{textStatus}</TextStyle>.
</SettingToggle>
                </Form>
              </Card>
              <Card></Card>
            </Layout.AnnotatedSection>
          </Layout>
          {active === true ?
            <Form onSubmit={handleFormSubmit}>
              <Layout>
                <Layout.AnnotatedSection
                  title="Tally Config"
                  description="Customize your app settings.">

                  <Card sectioned>
                    <TextField helpText="Use this API key to setup the tallify console app" label="API key" type="text" value={apiKey} disabled />
                    <TextStyle variation="subdued">Install a service:  TallySync.exe install -apikey "{apiKey}"</TextStyle>
                    <br /><TextStyle variation="subdued">Start a service:  TallySync.exe start -apikey "{apiKey}"</TextStyle>
                    <br /><TextStyle variation="subdued">Uninstall a service:  TallySync.exe uninstall -apikey "{apiKey}"</TextStyle>
                    <br /><TextStyle variation="subdued">Stop a service:  TallySync.exe stop -apikey "{apiKey}"</TextStyle>
                    <br /><br />
                    <FormLayout>
                      <FormLayout.Group>
                        <TextField label="Enter the tally-ip" type="text" placeholder="localhost" helpText="tally-ip (eg:localhost)" id="tally-ip" value={tallyIp} onChange={handleTallyIpChange} />
                        <TextField label="Enter the tally-port" type="text" placeholder="9000" helpText="tally-port (eg:9000)" id="tally-port" value={tallyPort} onChange={handleTallyPortChange} />

                        <Button
                          primary={true}
                          fullWidth={true}
                          onClick={handleVerifyPort}>
                          Verify</Button>
                        {tallyStatus === true ?
                          <TextStyle variation="positive">Tally is running on port number: {tallyPort}</TextStyle> :
                          <TextStyle variation="negative">Tally is not running on port number: {tallyPort}</TextStyle>}
                      </FormLayout.Group>
                    </FormLayout>
                  </Card>

                  <Card sectioned>
                    <FormLayout>
                      <FormLayout.Group>
                        <Select
                          label="Select tally company."
                          options={companyOptions}
                          onChange={handleCompanyNameChange}
                          value={selectedCompanyName}
                          disabled={false}
                        />
                        <Select
                          label="Select tally sync frequency(mins)"
                          options={frequencyOptions}
                          onChange={handleFrequencyChange}
                          value={frequency}
                          disabled={false}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                  </Card>
                </Layout.AnnotatedSection></Layout>
              <Layout>
                <Layout.AnnotatedSection>
                  <Card></Card>
                </Layout.AnnotatedSection>
              </Layout>
              <Layout>
                <Layout.AnnotatedSection
                  title="Inventory sync"
                  description="Select godowns">
                  <Card sectioned>
                    <Select
                      label="Sku field in item"
                      helpText="Location of an sku in a tally stock item(case sensitive)"
                      options={skuFieldOptions}
                      onChange={handleSkuFieldChange}
                      value={skuField}
                    /> <br />
                    <div><Subheading>Tally Godowns</Subheading><br></br>
                      <ChoiceList
                        allowMultiple
                        title="Select the godowns"
                        choices={godownOptions}
                        selected={selectedGodowns}
                        onChange={handleGodownCheckChange}
                      />
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
              </Layout>

              <Layout>
                <Layout.AnnotatedSection>
                  <Card></Card>
                </Layout.AnnotatedSection>
              </Layout>
              <Layout>
                <Layout.AnnotatedSection
                  title="Sales order"
                  description="Customize your app settings">

                  <Card sectioned>
                    <FormLayout>
                      {loading}
                      <Select
                        label="Select sales ledger"
                        options={salesLedgerOptions}
                        onChange={handleSelectSalesLedgerChange}
                        value={selectedSalesLedger}
                        disabled={false}
                      />
                      <Stack vertical>
                        <RadioButton
                          label="Party ledger fixed(based on ledger selection)"
                          helpText="Enable/Disable Party ledger"
                          checked={value === 'fixed'}
                          id="fixed"
                          name="ledger"
                          onChange={handleChange}
                        />
                        <RadioButton
                          label="Party ledger dynamic(autogenerate ledger based on orders)"
                          helpText="Enable/Disable Party ledger"
                          id="dynamic"
                          name="ledger"
                          checked={value === 'dynamic'}
                          onChange={handleChange}
                        />
                      </Stack>
                      <Select
                        label="Select Party ledger"
                        options={partyLedgerOptions}
                        onChange={handlePartLedgerChange}
                        value={selectedPartyLedger}
                        disabled={false}
                      />
                    </FormLayout>
                  </Card>
                </Layout.AnnotatedSection>
              </Layout>

              {toastMarkup}
              {toastErrorMarkup}

              <Layout>
                <Layout.AnnotatedSection>
                  <Card></Card>

                  <PageActions
                    primaryAction={{
                      content: 'Save',
                      submit: true,
                    }} /></Layout.AnnotatedSection>
              </Layout>
            </Form> : <Layout><Layout.AnnotatedSection><TallyEmptyState /></Layout.AnnotatedSection></Layout>}
        </Page> : <Setting />}
    </>

  );

  function goBack() {
    setGoBackActive(true);
  }
}
