// import React, { useState, useCallback } from 'react';
// import { Frame, Page, TextStyle } from '@shopify/polaris';
// const UseLoadingText =() => {
//     const [active, setActive] = useState(false);
//     const showLoadingText = useCallback(() => setActive(active => !active), []);
//     const textMarkup = active ? (
//     <div style={{ height: '50px' }}>
//     {/* <Frame> */}
//       {/* <Page>    */}
//         <TextStyle variation="positive">Please wait.It will take some time</TextStyle>
//       {/* </Page> */}
//       {/* </Frame> */}
//     </div>) : null;
    
// return[textMarkup,showLoadingText];
// }
// export default UseLoadingText;
import React, { useState } from 'react';
import { TextStyle } from '@shopify/polaris';

const UseLoadingText =() => {
    const [loadingText, setLoadingText] = useState(false);
    const showLoadingText = () => setLoadingText(true);
    const hideLoadingText = () => setLoadingText(false);
    const loading = loadingText ? <TextStyle variation="positive">Please wait... It will take some time...</TextStyle> : "";
    return[loading,showLoadingText,hideLoadingText];
}
export default UseLoadingText;