import React, { useEffect, useState } from 'react';
import './NavMenu.css';
// import '@shopify/polaris/build/esm/styles.css'
import Api from './Services/CommonServices';
import { Redirect } from 'react-router';
// import useLoader from './Loader/UseLoader';
import { environment } from './environments/environment.prod';
import { NavbarItem } from './NavbarItem';
const app_name = environment.appName;
const api = new Api();
export function NavMenu()  {
  const [shopExist,setShopExist] = useState();
  const [frame,setFrame] = useState();
  const [chargeStatus,setChargeStatus] = useState('');
  // const[loader,showLoader,hideLoader] = useLoader();
  useEffect(() => {
    getShopExists();
    // inIframe ();
    console.log("IFrame Status =",window.self !== window.top);
    setFrame(window.self !== window.top);
    if(shopExist === true)
    {
      // showLoader();
      api.chargeStatus(localStorage.getItem('shopUrl'))
      .then(res => {
        // console.log("inside canactivate =",res);
        setChargeStatus(res);
        
        // status = res;
      })
    // hideLoader();
    }
    
  }, []);

//   async function inIframe () {
//       let iFrame =  window.self !== window.top;
//       console.log("iframe=",iFrame)
//       setFrame(iFrame);
// }

  async function getShopExists()
  {
    // showLoader();
    api.checkIsShopExits(localStorage.getItem('shopUrl'))
    .then(res => {
      if(res === true)
      {
        getChargeStatus();
      }
      // console.log("inside canactivate =",res);
      setShopExist(res);
      // shopExist = res;
    })
    // hideLoader();
    // getChargeStatus();
  }
  async function getChargeStatus()
  {
    // showLoader();
    api.chargeStatus(localStorage.getItem('shopUrl'))
    .then(res => {
      // console.log("inside canactivate =",res);
      setChargeStatus(res);
      // status = res;
    })
    // hideLoader();
  }
    // console.log("inside render=",shopExist)
    // console.log("charge status=",chargeStatus)
    // if(localStorage.getItem('shopUrl') != null)
    // {
    //   window.open("https://the-frame-looter.myshopify.com/admin/apps/dtdc-courier", '_top');
    // }
    // if(shopExist === false) {
    //   return (api.generateAuthUrl(localStorage.getItem('shopUrl'))
    //     .then(res => {
    //       // console.log(`res = ${res}`);
    //       window.open(res, '_top');
    //     }, err => {
    //       console.log(`err = ${JSON.stringify(err)}`);
    //     }));
    // } else if(shopExist === true && chargeStatus === "Pending"){
    //   return (api.addCharge(localStorage.getItem('shopUrl'))
    //   .then(res => {
    //     console.log(`res = ${res}`);

    //     window.open(res, '_top');
        
    //   }, err => {
    //     console.log(`err = ${JSON.stringify(err)}`);
    //   }));
    // }

    if(shopExist === true && chargeStatus === "Pending"){
      return (api.addCharge(localStorage.getItem('shopUrl'))
      .then(res => {
        console.log(`res = ${res}`);
  
        window.open(res, '_top');
        
      }, err => {
        console.log(`err = ${JSON.stringify(err)}`);
      }));
    } else if(shopExist === false) {
      if(localStorage.getItem('shopUrl')!=null) {
        return (api.generateAuthUrl(localStorage.getItem('shopUrl'))
        .then(res => {
          console.log(`res = ${res}`);
          window.open(res, '_top');
        }, err => {
          console.log(`err = ${JSON.stringify(err)}`);
        }));
      } else {
        return (
          // <AppProvider i18n={enTranslations}>
            // <div style={style}>
            //   {loader} 
            // </div>
          <Redirect to='/VerifyShop' />
          // </AppProvider>
        )
      }
    }
    
    // else if(frame === false) {
    //   // console.log("frame in return =", frame)
    //   return(window.open(localStorage.getItem('shopUrl') + "/admin/apps/"+app_name ,'_top'))
    // }
    // else 
    // {
    //   return(
    //     <NavbarItem/>
    //   )
    // }
    else{
      return frame === false && shopExist === true && chargeStatus === "Active" ? (window.open("https://"+localStorage.getItem('shopUrl') + "/admin/apps/"+app_name ,'_top'))
                            : (<NavbarItem/>)                           
    }
    
    }