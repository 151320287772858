import React, { useCallback, useState, useEffect, useRef } from 'react';
import { ChoiceList, Scrollable, FormLayout, PageActions, Form, Caption, Subheading, Card, Select } from '@shopify/polaris';
import useLoader from '../Loader/UseLoader';
import Api from '../Services/CommonServices';
import useSuccessToast from '../Toast/UseSuccessToast';
import useErrorToast from '../Toast/UseErrorToast';
import { environment } from '../environments/environment.prod';
const api = new Api();
const endpoint = environment.serverUrl;
export function QboConfig() {

  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast()
  const [accSelected, setAccSelected] = useState();
  // const handleAccSelectChange = useCallback((value) => { console.log(value); setAccSelected(value), [] });
  let [accounts, setAccounts] = useState([]);
  let [customers, setCustomers] = useState([]);
  const [customerSelected, setCustomerSelected] = useState();
  // const handleCustomerSelectChange = useCallback((value) => setCustomerSelected(value), []);
  const [btnLoad, setBtnLoad] = useState();
  const shopOrigin = useRef('');
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    console.log("shopOrigin=",shopOrigin);
    getQBOConfig();
  }, []);

  async function getQBOAccounts() {
    showLoader();
    api.getQBOAccounts(shopOrigin.current.shop)
      .then(response => {
        setAccounts(response)
        hideLoader();
      })
  }

  function handleAccSelectChange(value)
  {
    console.log("acc selected=",value);
    setAccSelected(value);
  }
  
  function handleCustomerSelectChange(value)
  {
    console.log("cus selected=",value);
    setCustomerSelected(value);
  }

  async function getQBOConfig() {
    showLoader();
    api.getQboConfigs(shopOrigin.current.shop)
      .then(response => {
        console.log("config response=",response)
        if (response != null) {
          setAccSelected('' + response.qboAccountId)
          setCustomerSelected(response.customerGraphqlId)
          getQBOAccounts();
          getCustomers();
        }
        else
        {
          hideLoader();
        }
      })
  }

  async function getCustomers() {
    api.getCustomers(shopOrigin.current.shop)
      .then(response => {
        setCustomers(response)
      })
  }

  async function addQboConfigs(acc, cust) {
    setBtnLoad(true)
    var account = acc
    var customer = cust
    const data = {
      QboAccountId: account == undefined  ? accounts[0].value : account == 'undefined' ? accounts[0].value : account,
      customerGraphqlId: customer == undefined ? customers[0].value : customer == 'undefined' ? customers[0].value : customer,
      Shop: shopOrigin.current.shop,
    };
    console.log("values=", data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // console.log(requestOptions)
    fetch(endpoint + '/api/QBOConfig/add-update-QboConfig', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          setBtnLoad(false)
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }
  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    console.log("Acc selected=",accSelected,customerSelected)
    addQboConfigs(accSelected, customerSelected)
  }


  return (
    <>
      <Form onSubmit={handleFormSubmit}>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <div><Subheading>Select Sales Account</Subheading><br></br>
                <Caption>Sales account needed to sync sales orders to customers</Caption><br></br>
                <Select
                  options={accounts}
                  onChange={e => handleAccSelectChange(e)}
                  value={accSelected}
                />
              </div>
              <div><Subheading>Select Default Customer</Subheading><br></br>
                <Caption>Default customer needed to assign customer to sales without customer</Caption><br></br>
                <Select
                  options={customers}
                  onChange={e => handleCustomerSelectChange(e)}
                  value={customerSelected}
                />
              </div>
            </FormLayout.Group>
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: 'Save',
            submit: true
          }}
        />
      </Form>
      {loader}
      {toastMarkup}
      {toastErrorMarkup}
    </>
  );
}

