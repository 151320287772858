import { AppProvider, Banner, CalloutCard, Layout, Link, TextContainer } from '@shopify/polaris';
import React, { useEffect, useRef, useState } from 'react';
import { environment } from './environments/environment.prod';
import { QboSetting } from './QuickbooksSetup/QboSetting';
import Api from './Services/CommonServices';
import { TallyConfig } from './TallySetup/TallyConfig';
const api = new Api();
const endpoint = environment.serverUrl;
export function Setting() {

  const [qboSetup,setQboSetUp] = useState(false);
  const [tallySetup,setTallySetUp] = useState(false);
  const shopOrigin = useRef('');
  const [companyName, setCompanyName] = useState('');
  const [connected, setConnected] = useState(false);
  const details = connected ? companyName + ' connected' : 'No account connected';
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    console.log("settings shop=",shopOrigin.current)
    QboAppData();
  }, []);

  const qboSetting = (
    <QboSetting/>
  );

  const tallySetting = (
    <TallyConfig/>
  );


  function QboAppData() {
    // showLoader();
    api.QboAppData(shopOrigin.current.shop).then(response => {
      console.log(response.companyName)
      response.companyName !== undefined ? setConnected(true) : setConnected(false)
      setCompanyName(response.companyName);
    })
    // hideLoader();
  }


  const homePage = (
<Layout sectioned>
        <Layout.AnnotatedSection
          title="Setup"
          description="Finish your setup by enabling Integration and saving the config">
          <CalloutCard
            title="Let's configure Tally"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: 'Tally setup',
              onAction: () => OpenTallySetup(),
            }}
          >
            <p>You are a few steps away from using Tally sync.Click Setup below to start.</p>
          </CalloutCard>

        <CalloutCard
          title="Let's configure Quickbooks"
          illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"

          primaryAction={{
            content: 'Quickbooks setup',
            onAction: () => OpenQboSetup(),
          }}
        >
          <p>You are a few steps away from using Quickbooks sync. Click Setup below to start.</p>
          <TextContainer>
            {connected == true ? 
            <Banner onDismiss={false} status="success">
              <p>
              {details}
              </p>
            </Banner>
            : <Banner onDismiss={false} status="critical">
            <p>
            {details}
            </p>
          </Banner> } 
          </TextContainer>
        </CalloutCard>
        </Layout.AnnotatedSection>
      </Layout>
  );

  return (
    <>
      { (qboSetup == false && tallySetup == false) ? homePage : tallySetup == true ? tallySetting : qboSetup == true ? qboSetting : homePage}
    </>
  );


  function OpenQboSetup()
  {
    setQboSetUp(true);
  }

  function OpenTallySetup()
  {
    setTallySetUp(true);
  }

} 