import axios from 'axios';
import { environment } from '../environments/environment.prod';
const endpoint = environment.serverUrl;
const dbUrl = environment.firebaseUrl;
  class Api {

    getTallyCompanies=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/TallyConfig/tally-companies?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    getTallyPartyLedger=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/TallyConfig/party-ledger?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    getTallySalesLedger=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/TallyConfig/sales-ledger?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    generateAuthUrl=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/Shop/get-auth-url?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    createRecurringCharge(shopUrl) {
      return axios
      .get(endpoint+'/api/RecurringCharge/add?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    verifyShop=(queryString) =>{
      if (!queryString) {
        queryString = this.queryString;
      }
      return axios
      .get(endpoint+'/api/Shop/verify-request' + queryString)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    checkIsShopExits=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/Shop/IsShopExists?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    chargeStatus=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/RecurringCharge/chargestatus?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    addCharge=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/RecurringCharge/create?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    getTallyConfig = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/TallyConfig/tallyConfigs-of-shop?shopUrl='+ shopUrl)
        .then(function(response) {
          if (response.status === 200 && response != null) {
            var data = response.data
            
            // console.log('tally config =',data);
            return data
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    // getTallyGodowns = (shopUrl) => {
    //   // console.log(shopUrl);
    //   return axios
    //     .get(endpoint+'/api/TallyConfig/godownlist-fromdb?shopUrl='+ shopUrl)
    //     .then(function(response) {
    //       // console.log(response)
    //       if (response.status === 200 && response != null) {
    //         var data = response.data
            
    //         console.log('tally godowns =',data);
    //         return data
            
    //       } else {
    //         throw new Error('Empty data')
    //       }
    //     })
    //     .catch(function(error) {
    //       console.log(error)
    //       return [] // Return empty array in case error response.
    //     })
    // }

    getTallyGodowns = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/TallyConfig/godown-list?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            // var data = response.data
            
            // console.log('tally godowns =',data);
            return response
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    TallyPortVerification = (status,shopUrl) => {
      // console.log(shopUrl);
      return axios
        .post(endpoint+'/api/TallyConfig/verify-connection?status='+status+'&shopUrl=' + shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    CheckConnectionStatus = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .post(endpoint+'/api/TallyConfig/check-connection-status?shopUrl=' + shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    getQBOAccounts = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/QBOInvoice/Account?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            
            // console.log('accounts =',data);
            return data
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    getCustomers = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .post(endpoint+'/api/Customer/fetch_customers?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            
            // console.log('customers =',data);
            return data
            
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }
    // getOrders = (shopUrl) => {
    //   // console.log(shopUrl);
    //   return axios
    //     .get(endpoint+'/api/Order?shopUrl='+ "tpt-staging.myshopify.com")
    //     .then(function(response) {
    //       // console.log(response)
    //       if (response.status === 200 && response != null) {
    //         var data = response.data
            
    //         console.log('tally godowns =',data);
    //         return data
            
    //       } else {
    //         throw new Error('Empty data')
    //       }
    //     })
    //     .catch(function(error) {
    //       console.log(error)
    //       return [] // Return empty array in case error response.
    //     })
    // }
    getAllOrders(shopUrl){
      return axios
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .get(endpoint+'/api/Order/list-orders?shopUrl='+ shopUrl)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    getGraphqlOrders(shopUrl,cursor){
      return axios
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .get(endpoint+'/api/Order/all-fields?shopUrl='+ shopUrl+'&cursor='+cursor)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }

    getGraphqlPrevOrders(shopUrl,cursor){
      return axios
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .get(endpoint+'/api/Order/prev-orders?shopUrl='+shopUrl+'&cursor='+cursor)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }

    QboAuthorization(shopUrl){
      return axios
      .get(endpoint+'/api/QBOAuth/get-auth-url?shopUrl='+shopUrl)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    DeleteQboAuthorization(shopUrl){
      return axios
      .delete(endpoint+'/api/QBOApp?shopUrl='+shopUrl)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    QboAppData(shopUrl){
      return axios
      .get(endpoint+'/api/QBOApp/QBOApp?shopUrl='+shopUrl)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    getSalesInvoiceLogs(shopUrl,id){
      console.log(endpoint+'/api/QBOInvoice/sales-invoice-logsbyid?shopUrl='+shopUrl+'&id='+id)
      return axios
      .get(endpoint+'/api/QBOInvoice/sales-invoice-logsbyid?shopUrl='+shopUrl+'&id='+id)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    getAllInvoiceLogs(shopUrl,id){
      // console.log(endpoint+'/api/QBOInvoice/sales-invoice-logs?shopUrl='+shopUrl)
      return axios
      .get(endpoint+'/api/QBOInvoice/sales-invoice-logs?shopUrl='+shopUrl)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    QboCreateSalesInvoice(shopUrl){
      return axios
      .get(endpoint+'/api/123')
      .then(function(response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }

    getQboConfigs = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/QBOConfig/qboConfigs-of-shop?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            console.log("qboConfigs data =",response)
            return data
          } else {
            // throw new Error('Empty data')
            return null;
          }
        })
        .catch(function(error) {
          console.log("error=",error)
          return null; // Return empty array in case error response.
        })
    }

    getStore = (shopUrl) => {
      return axios
        .get(endpoint+'/api/Shop?shopUrl='+shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    deleteTallyResponse = (shopUrl) => {
      return axios
        .delete(endpoint+'/api/TallyConfig/tally-response?shopUrl='+shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    deleteConnectionStatus = (shopUrl) => {
      return axios
        .delete(endpoint+'/api/TallyConfig/connection-status?shopUrl='+shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    

      getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }

      QueryStringToJSON() {
        let queryString = "";
        queryString = window.location.search;
        console.log(`query string = ${queryString}`);
        if (!queryString) {
          queryString = this.queryString;
        }
        let result = {};
        if(queryString) {
          this.queryString = queryString;
          const pairs = queryString.replace('?', '').split('&');

          pairs.forEach(function(pair) {
              pair = pair.split('=');
              result[pair[0]] = decodeURIComponent(pair[1] || '');
          });
        }

        return JSON.parse(JSON.stringify(result));
      }

  }
  export default Api
