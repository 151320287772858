import React, { Component } from 'react';
// import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout1 extends Component {
  static displayName = Layout1.name;

  render () {
    
    return (
      
      <div>
        {/* <NavMenu /> */}
        <NavMenu/>
        {/* <Container> */}
          {this.props.children}
        {/* </Container> */}
      </div>
    );
    
  }
}
