import React, { useEffect, useRef } from 'react';
import { Banner, EmptyState} from '@shopify/polaris';
  export function TallyEmptyState() {
    const banner = useRef();

  useEffect(() => banner.current.focus(), []);

  return (
    <Banner
      title="Tallify Integration is Disabled"
      onDismiss={false}
      status="critical"
      ref={banner}
    >
      <p>
      Enabling Tallify will take you to Configuration .
      </p>
    </Banner>
  );
}